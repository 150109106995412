<template>
  <div>
    <b-modal v-model="show" centered 
      :header-class="['py-2', 'bg-dagee']"
      :footer-class="['p-0', 'bg-white']">

      <template v-slot:modal-header="{ close }">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="flex-fill">
            <b>景點編輯</b>
          </div>
          <div @click="close()">
            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
          </div>
        </div>
      </template>

      <template v-slot:default>
        <div class="d-flex align-items-center">
          
        </div>
      </template>

      <template v-slot:modal-footer="{ ok, hide, cancel }">
        <div class="w-100 d-flex align-items-center justify-content-between">
          
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import ctrl from "./js/EditElement";
  export default ctrl;

</script>

export default {
  data() {
    return {
      show: true
    }
  },

  computed: {

  },

  props: {
    pointElement: {
      type: Object,
      default: {}
    }
  }
}
